import { SVGProps } from 'react';

export function ShareArrowRightIcon(props: Readonly<SVGProps<SVGSVGElement>>) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 12"
      {...props}
    >
      <path
        d="M11.7945 4.29445L10.4843 2.97965C9.54029 2.03232 9.17025 1.52271 8.65908 1.70287C8.02165 1.92751 8.23149 3.34494 8.23149 3.82346C7.24045 3.82346 6.21011 3.73538 5.23323 3.91891C2.00839 4.52475 1 7.14387 1 10.0003C1.91273 9.35379 2.82456 8.66501 3.92155 8.36536C5.29091 7.99131 6.82026 8.16981 8.23149 8.16981C8.23149 8.6483 8.02165 10.0658 8.65908 10.2904C9.23833 10.4945 9.54029 9.96095 10.4843 9.01362L11.7945 7.69882C12.5982 6.89235 13 6.48911 13 5.99663C13 5.50416 12.5982 5.10092 11.7945 4.29445Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
